import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Icon,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    List as MuiList,
    ListItem as MuiListItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import {
    LoadingButton,
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab";

import {
    AddOutlined,
    ArrowDropDown,
    Edit,
    Info,
    InfoRounded,
} from "@mui/icons-material";

import * as Yup from "yup";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { addDays, format } from "date-fns";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { Paper, WorkspaceToolbar } from "../../components/common";
import {
    useCreateDiscount,
    useCreatePriceRule,
    useGetPriceRuleById,
    useUpdatePriceRule,
} from "../../hooks";
import { useAuth, useMessage, useToggleCallbacks } from "../../sdk/hooks";
import { IAdmin } from "../../sdk/types";
import {
    DiscountCodeStatus,
    DiscountTypeNames,
    PaymentMethodNames,
    PaymentMethodType,
    PriceRuleCategoryNames,
    PriceRuleCategoryType,
    PriceRuleStatus,
    priceRuleStatusNames,
} from "../../utils/enums";
import {
    AllocationMethod,
    CustomerSelection,
    DiscountType,
} from "../../utils/enums";

import { CustomerGroups } from "./CustomerGroups";
import { DiscountCodeDataGrid } from "./DiscountCodeDataGrid";
import { GeneratorDialog } from "./GeneratorDialog";
import { ProductVariants } from "./ProductVariants";

const Root = styled("main")`
    padding: ${({ theme }) => theme.spacing(4, 0)};

    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
      gap: ${theme.spacing(3)};
    }
  `}
`;

const LeftContainer = styled("section")`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    max-width: 800px;
    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: 100%;
    }
  `}
`;

const RightContainer = styled("section")`
    min-width: 350px;
    max-width: 500px;
    padding-top: ${({ theme }) => theme.spacing(6)};

    flex: 0.5;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          padding-top: 0px;
          min-width: 100%;
          max-width: 100%;
    }
  `}
`;

const SummaryContainer = styled("section")`
    position: sticky;
    top: 95px;

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 22px;
    font-weight: 600;
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #626262;
`;

const DiscountSection = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

const MultilineTextFieldContainer = styled("div")`
    height: 80px;
`;

const SubtitleContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const SelectField = styled(Select)`
    height: 40px;
`;

const DateContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    align-items: start;
    }
  `}
`;

const List = styled(MuiList)`
    list-style-type: disc;
    padding: 0 0px 0px 18px;
`;

const ListItem = styled(MuiListItem)`
    font-family: "Poppins";
    display: list-item;
    padding: 8px 0px;
`;

const Container = styled("div")`
    width: 100%;
    padding: ${({ theme }) => theme.spacing(0, 6)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        padding: ${({ theme }) => theme.spacing(0, 3)};
    }
`;

const TextFieldContainer = styled("div")`
    height: 55px;
    flex: 1;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
        width: 100%;
    }
  `}
`;

const ErrorMessage = styled(FormHelperText)`
    padding: ${({ theme }) => theme.spacing(1)};
    color: red;
`;

const RadioErrorMessage = styled(FormHelperText)`
    color: red;
    margin-left: 30px;
`;

const StyledTooltip = styled(Tooltip)`
    cursor: default;
`;

const StyledArrowDown = styled(ArrowDropDown)`
    margin-right: ${({ theme }) => theme.spacing(3)};
`;

const StyledTimelineContent = styled(TimelineContent)`
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
`;

const StyledTimeline = styled(Timeline)`
    padding: 0;
    margin: ${({ theme }) => theme.spacing(1, 0, 0)};
    .MuiTimelineItem-root:before {
        content: none;
    }
`;

const TimelineText = styled(Typography)`
    font-size: 14px;
`;

const TimelineTitle = styled(TimelineText)`
    font-weight: 500;
    color: #626262;
`;

const StyledTimelineDot = styled(TimelineDot)`
    box-shadow: none;
`;

const priceRuleStatusList = Object.values(PriceRuleStatus).filter(
    (value) => typeof value !== "string",
);
const paymentMethodsList = Object.values(PaymentMethodType);
const priceRuleCategoryList = Object.values(PriceRuleCategoryType).filter(
    (value) => typeof value !== "string",
);
const discountTypeList = Object.values(DiscountType).filter(
    (value) => typeof value !== "string",
);

const validationSchema = Yup.object({
    name: Yup.string()
        .min(1)
        .required("Please enter a valid name for this discount"),
    description: Yup.string()
        .min(1)
        .required("Please enter a valid description for this discount"),
    status: Yup.number()
        .oneOf([
            PriceRuleStatus.DRAFT,
            PriceRuleStatus.ACTIVE,
            PriceRuleStatus.ARCHIVED,
        ])
        .required("Please select a status for this discount"),
    discountCodes: Yup.array().of(
        Yup.object().shape({
            code: Yup.string(),
            id: Yup.string(),
        }),
    ),
    category: Yup.number()
        .oneOf([
            PriceRuleCategoryType.PAYMENT_METHODS,
            PriceRuleCategoryType.UI_DISCOUNT_CODES,
            PriceRuleCategoryType.PRIVATE_DISCOUNTS,
        ])
        .required("Please select a discount category"),
    discountType: Yup.number()
        .oneOf([DiscountType.FIXED, DiscountType.PERCENTAGE])
        .required("Please select a discount type"),
    discountValue: Yup.number()
        .required("Please specify discount value")
        .when("discountType", (value, field) => {
            const discountType = value[0];
            return discountType === DiscountType.PERCENTAGE
                ? field.max(100, "Maximum discount percentage allowed is 100%")
                : field;
        }),
    prereqSubtotalMin: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    allocationMethod: Yup.number()
        .oneOf([
            AllocationMethod.LOWEST_LINE_ITEMS,
            AllocationMethod.HIGHEST_LINE_ITEMS,
            AllocationMethod.COMBINED_LINE_ITEMS,
            AllocationMethod.EACH_LINE_ITEM,
        ])
        .required("Please select an allocation method"),
    prereqPaymentMethod: Yup.string()
        .oneOf([PaymentMethodType.COD, PaymentMethodType.PREPAID])
        .nullable()
        .when("category", (value, field) => {
            const category = value[0];
            return category === PriceRuleCategoryType.PAYMENT_METHODS
                ? field.required("Please select a payment method")
                : field;
        }),
    prereqQuantityMin: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    usageLimitByTotal: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    usageLimitByCustomer: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    prereqVariantsRequired: Yup.number()
        .min(0, "Minimum value is 0")
        .nullable(),
    variantIds: Yup.array().of(Yup.string()).min(0),
    customerSelection: Yup.number()
        .oneOf([CustomerSelection.ALL, CustomerSelection.PREREQ])
        .required("Please select customer eligibility"),
    prereqCustomerGroupIds: Yup.array().when(
        "customerSelection",
        (value, field) => {
            const customerSelection = value[0];
            return customerSelection === CustomerSelection.PREREQ
                ? field.min(1, "Please select atleast one customer group")
                : field;
        },
    ),
    startDate: Yup.date(),
    startTime: Yup.date(),
    enableEndTime: Yup.boolean().default(false),
    endDate: Yup.date().when("enableEndTime", (value, field) =>
        value ? field.required("Please enter end date") : field,
    ),
    endTime: Yup.date().when("enableEndTime", (value, field) =>
        value ? field.required("Please enter end time") : field,
    ),
    maxDiscountAmount: Yup.number().nullable(),
});

export const DiscountScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const newPriceRule = params.id === "new";
    const navigate = useNavigate();

    const priceRule = useGetPriceRuleById("priceRule", params.id);
    const createMutation = useCreatePriceRule("priceRule");
    const createDiscountCode = useCreateDiscount("discount");
    const updateMutation = useUpdatePriceRule("priceRule");
    const message = useMessage();
    const auth = useAuth();

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        dirty,
        isValid,
    } = useFormik({
        initialValues: {
            name: "",
            description: "",
            status: PriceRuleStatus.DRAFT,
            discountCodes: [] as {
                id: string;
                code: string;
                status: DiscountCodeStatus;
                createdBy: IAdmin;
            }[],
            category: PriceRuleCategoryType.UI_DISCOUNT_CODES,
            discountType: DiscountType.FIXED,
            discountValue: "",
            prereqSubtotalMin: "",
            prereqQuantityMin: "",
            prereqPaymentMethod: "cod",
            prereqVariantsRequired: 0,
            variantIds: [],
            allocationMethod: AllocationMethod.COMBINED_LINE_ITEMS,
            customerSelection: CustomerSelection.ALL,
            prereqCustomerGroupIds: [],
            usageLimitByTotal: "",
            usageLimitByCustomer: "",
            startDate: new Date(),
            startTime: new Date(),
            enableEndTime: false,
            endDate: addDays(new Date(), 5),
            endTime: addDays(new Date(), 5),
            maxDiscountAmount: "",
        },
        onSubmit: async (values) => {
            const {
                discountCodes,
                startDate,
                startTime,
                endDate,
                endTime,
                usageLimitByTotal,
                usageLimitByCustomer,
                discountValue,
                discountType,
                category,
                prereqQuantityMin,
                prereqSubtotalMin,
                prereqPaymentMethod,
                allocationMethod,
                prereqVariantsRequired,
                customerSelection,
                prereqCustomerGroupIds,
                enableEndTime,
                status,
                maxDiscountAmount,
                ...priceRuleParameter
            } = values;
            if (newPriceRule) {
                try {
                    const priceRuleResponse = await createMutation.mutateAsync({
                        ...priceRuleParameter,
                        category,
                        discountValue: Number(discountValue),
                        usageLimitByTotal:
                            values.usageLimitByTotal !== ""
                                ? Number(usageLimitByTotal)
                                : -1,
                        usageLimitByCustomer:
                            values.usageLimitByCustomer !== ""
                                ? Number(usageLimitByCustomer)
                                : -1,
                        prereqQuantityMin:
                            prereqQuantityMin === ""
                                ? -1
                                : Number(prereqQuantityMin),
                        prereqSubtotalMin:
                            prereqSubtotalMin === ""
                                ? -1
                                : Number(prereqSubtotalMin),
                        startsAt: new Date(
                            `${format(startDate, "MM/dd/yyyy")} ${format(
                                startTime,
                                "HH:mm:ss",
                            )}`,
                        ),
                        endsAt: enableEndTime
                            ? new Date(
                                  `${format(endDate, "MM/dd/yyyy")} ${format(
                                      endTime,
                                      "HH:mm:ss",
                                  )}`,
                              )
                            : null,
                        allocationMethod: Number(allocationMethod),
                        customerSelection: Number(customerSelection),
                        prereqCustomerGroupIds:
                            Number(customerSelection) ===
                            CustomerSelection.PREREQ
                                ? prereqCustomerGroupIds
                                : [],
                        discountType: Number(discountType),
                        prereqPaymentMethod:
                            category !== PriceRuleCategoryType.PAYMENT_METHODS
                                ? null
                                : prereqPaymentMethod,
                        prereqVariantsRequired: Number(prereqVariantsRequired),
                        variantIds: values.variantIds,
                        status: Number(status),
                        maxDiscountAmount:
                            maxDiscountAmount === ""
                                ? null
                                : Number(maxDiscountAmount),
                    });

                    if (values.discountCodes.length > 0) {
                        await createDiscountCode.mutateAsync(
                            {
                                priceRuleId: priceRuleResponse.id,
                                codes: values.discountCodes.map((codes) => ({
                                    code: codes.code,
                                    status: codes.status,
                                })),
                            },
                            {
                                onSuccess: () => {
                                    message.showSuccess(
                                        "Discount created successfully",
                                    );
                                },
                            },
                        );
                    }
                } catch (error: any) {
                    console.error(error);
                    message.showError(error);
                }
                navigate("/discounts");
            } else {
                await updateMutation.mutateAsync(
                    {
                        ...priceRuleParameter,
                        id: params.id ?? "",
                        usageLimitByTotal:
                            values.usageLimitByTotal !== ""
                                ? Number(usageLimitByTotal)
                                : -1,
                        usageLimitByCustomer:
                            values.usageLimitByCustomer !== ""
                                ? Number(usageLimitByCustomer)
                                : -1,
                        discountValue: Number(discountValue),
                        maxDiscountAmount:
                            maxDiscountAmount === ""
                                ? null
                                : Number(maxDiscountAmount),
                        prereqQuantityMin:
                            prereqQuantityMin === ""
                                ? -1
                                : Number(prereqQuantityMin),
                        prereqSubtotalMin:
                            prereqSubtotalMin === ""
                                ? -1
                                : Number(prereqSubtotalMin),
                        startsAt: new Date(
                            `${format(startDate, "MM/dd/yyyy")} ${format(
                                startTime,
                                "HH:mm:ss",
                            )}`,
                        ),
                        endsAt: enableEndTime
                            ? new Date(
                                  `${format(endDate, "MM/dd/yyyy")} ${format(
                                      endTime,
                                      "HH:mm:ss",
                                  )}`,
                              )
                            : null,
                        allocationMethod: Number(allocationMethod),
                        prereqPaymentMethod:
                            category !== PriceRuleCategoryType.PAYMENT_METHODS
                                ? null
                                : prereqPaymentMethod,
                        prereqVariantsRequired: Number(prereqVariantsRequired),
                        category,
                        discountType: Number(discountType),
                        customerSelection: Number(customerSelection),
                        prereqCustomerGroupIds:
                            Number(customerSelection) ===
                            CustomerSelection.PREREQ
                                ? prereqCustomerGroupIds
                                : [],
                        variantIds: values.variantIds,
                        status: Number(status),
                    },
                    {
                        onSuccess: () => {
                            navigate("/discounts");
                            return;
                        },
                    },
                );
            }
        },
        validationSchema: validationSchema,
    });

    useEffect(() => {
        if (Boolean(priceRule.data) && priceRule.isSuccess) {
            setFieldValue("discountCodes", priceRule.data.discountCodes);
            setFieldValue("discountType", priceRule.data.discountType);
            setFieldValue("discountValue", priceRule.data.discountValue);
            setFieldValue(
                "prereqSubtotalMin",
                priceRule.data.prereqSubtotalMin > 0
                    ? priceRule.data.prereqSubtotalMin
                    : "",
            );
            setFieldValue(
                "prereqQuantityMin",
                priceRule.data.prereqQuantityMin > 0
                    ? priceRule.data.prereqQuantityMin
                    : "",
            );
            setFieldValue(
                "customerSelection",
                priceRule.data.customerSelection,
            );
            setFieldValue(
                "prereqCustomerGroupIds",
                priceRule.data.prereqCustomerGroupIds,
            );
            setFieldValue(
                "usageLimitByTotal",
                priceRule.data.usageLimitByTotal > 0
                    ? priceRule.data.usageLimitByTotal
                    : "",
            );
            setFieldValue(
                "prereqVariantsRequired",
                Number(priceRule.data.prereqVariantsRequired),
            );
            setFieldValue(
                "prereqPaymentMethod",
                priceRule.data.prereqPaymentMethod,
            );
            setFieldValue("variantIds", priceRule.data.variantIds);

            setFieldValue(
                "usageLimitByCustomer",
                priceRule.data.usageLimitByCustomer > 0
                    ? priceRule.data.usageLimitByCustomer
                    : "",
            );
            setFieldValue("startDate", new Date(priceRule.data.startsAt));
            setFieldValue("startTime", new Date(priceRule.data.startsAt));
            setFieldValue("enableEndTime", Boolean(priceRule.data.endsAt));
            setFieldValue(
                "endDate",
                priceRule.data.endsAt !== null
                    ? new Date(priceRule.data.endsAt)
                    : addDays(new Date(), 5),
            );
            setFieldValue(
                "endTime",
                priceRule.data.endsAt !== null
                    ? new Date(priceRule.data.endsAt)
                    : addDays(new Date(), 5),
            );
            setFieldValue("name", priceRule.data.name);
            setFieldValue("description", priceRule.data.description);
            setFieldValue("status", priceRule.data.status);
            setFieldValue("category", priceRule.data.category);
            setFieldValue("allocationMethod", priceRule.data.allocationMethod);
            setFieldValue(
                "maxDiscountAmount",
                priceRule.data.maxDiscountAmount,
            );
        }
    }, [priceRule.data, priceRule.isSuccess, setFieldValue]);

    const handleStartDateChange = useCallback(
        (newValue: any) => {
            setFieldValue("startDate", newValue);
        },
        [setFieldValue],
    );

    const handleStartTimeChange = useCallback(
        (newValue: any) => {
            setFieldValue("startTime", newValue);
        },
        [setFieldValue],
    );

    const handleEndDateChange = useCallback(
        (newValue: any) => {
            setFieldValue("endDate", newValue);
        },
        [setFieldValue],
    );

    const handleEndTimeChange = useCallback(
        (newValue: any) => {
            setFieldValue("endTime", newValue);
        },
        [setFieldValue],
    );

    const handlePrereqCustomerGroupChange = useCallback(
        (newValue: any) => {
            setFieldValue("prereqCustomerGroupIds", newValue);
        },
        [setFieldValue],
    );

    const handlePrereqVariantIdsChange = useCallback(
        (newValue: any) => {
            setFieldValue("variantIds", newValue);
        },
        [setFieldValue],
    );

    const disableScrollWheelInput = useCallback(
        (event: React.WheelEvent<HTMLDivElement>) => {
            event.currentTarget.querySelector("input")?.blur();
        },
        [],
    );

    const onAddDiscountCode = useCallback(
        (index: number, value: string, status: DiscountCodeStatus) => {
            const updatedCodes = [...values.discountCodes];
            updatedCodes[index] = {
                code: value,
                id: "",
                status,
                createdBy: auth.user!,
            };
            setFieldValue("discountCodes", updatedCodes);
        },
        [auth.user, setFieldValue, values.discountCodes],
    );

    const [openGenerator, setOpenGenerator] = useState(false);
    const [handleOpenGenerator, handleCloseGenerator] =
        useToggleCallbacks(setOpenGenerator);

    return (
        <>
            <WorkspaceToolbar
                title={newPriceRule ? "Create discount" : "Edit discount"}
                enableSearch={false}
                actions={
                    <>
                        {!newPriceRule && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<Icon>playlist_add</Icon>}
                                onClick={handleOpenGenerator}
                                disabled={priceRule.isLoading}
                            >
                                Generate Discount Codes
                            </Button>
                        )}
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>check_circle</Icon>}
                            onClick={handleSubmit as any}
                            disabled={!dirty || !isValid}
                            loading={
                                createMutation.isLoading ||
                                updateMutation.isLoading
                            }
                        >
                            Save
                        </LoadingButton>
                    </>
                }
                breadcrumbs={[
                    {
                        title: "Discounts",
                        link: "/discounts",
                        icon: <Icon fontSize="small">percent</Icon>,
                    },
                    {
                        title: newPriceRule
                            ? "Create discount"
                            : priceRule.data?.name ?? "",
                        link: `/discounts/${params.id}`,
                    },
                ]}
            />
            <Container>
                <Root>
                    <LeftContainer>
                        <Title>Create product discount</Title>
                        {/* General */}
                        <Paper>
                            <DiscountSection>
                                <SectionTitle>General</SectionTitle>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            fullWidth={true}
                                            required={true}
                                            name="name"
                                            label="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.name) &&
                                                touched.name
                                            }
                                            helperText={
                                                touched.name && errors.name
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer>
                                        <FormControl
                                            size="small"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel>Status</InputLabel>
                                            <SelectField
                                                name="status"
                                                label="Status"
                                                fullWidth={true}
                                                size="small"
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    Boolean(errors.status) &&
                                                    touched.status
                                                }
                                                IconComponent={(props) => (
                                                    <StyledArrowDown
                                                        {...props}
                                                    />
                                                )}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                <>
                                                                    Status of
                                                                    the
                                                                    discount.
                                                                    Only active
                                                                    discounts
                                                                    are
                                                                    available
                                                                    for use.
                                                                </>
                                                            }
                                                        >
                                                            <InfoRounded
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                            >
                                                {priceRuleStatusList.map(
                                                    (status) => (
                                                        <MenuItem
                                                            key={status}
                                                            value={status}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    priceRuleStatusNames[
                                                                        Number(
                                                                            status,
                                                                        )
                                                                    ]
                                                                }
                                                            />
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </SelectField>
                                            {Boolean(errors.status) &&
                                                touched.status && (
                                                    <RadioErrorMessage>
                                                        {errors.status}
                                                    </RadioErrorMessage>
                                                )}
                                        </FormControl>
                                    </TextFieldContainer>
                                </Row>
                                <MultilineTextFieldContainer>
                                    <TextField
                                        fullWidth={true}
                                        required={true}
                                        multiline
                                        rows={2}
                                        name="description"
                                        label="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.description) &&
                                            touched.description
                                        }
                                        helperText={
                                            touched.description &&
                                            errors.description
                                        }
                                    />
                                </MultilineTextFieldContainer>
                                <Row>
                                    <TextFieldContainer>
                                        <FormControl
                                            size="small"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel>
                                                Discount type
                                            </InputLabel>
                                            <SelectField
                                                name="discountType"
                                                label="Discount type"
                                                fullWidth={true}
                                                size="small"
                                                value={values.discountType}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    Boolean(
                                                        errors.discountType,
                                                    ) && touched.discountType
                                                }
                                            >
                                                {discountTypeList.map(
                                                    (type) => (
                                                        <MenuItem
                                                            key={type}
                                                            value={type}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    DiscountTypeNames[
                                                                        type as DiscountType
                                                                    ]
                                                                }
                                                            />
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </SelectField>
                                            {Boolean(errors.discountType) &&
                                                touched.discountType && (
                                                    <RadioErrorMessage>
                                                        {errors.discountType}
                                                    </RadioErrorMessage>
                                                )}
                                        </FormControl>
                                    </TextFieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            name="discountValue"
                                            value={values.discountValue}
                                            label={"Discount value"}
                                            fullWidth={true}
                                            required={true}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            type="number"
                                            onBlur={handleBlur}
                                            onWheel={disableScrollWheelInput}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {values.discountType ===
                                                        DiscountType.FIXED
                                                            ? "₹"
                                                            : "%"}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                Boolean(errors.discountValue) &&
                                                touched.discountValue
                                            }
                                            helperText={
                                                touched.discountValue &&
                                                errors.discountValue
                                            }
                                        />
                                    </TextFieldContainer>
                                </Row>
                                {values.discountType ===
                                    DiscountType.PERCENTAGE && (
                                    <Row>
                                        <TextFieldContainer>
                                            <TextField
                                                name="maxDiscountAmount"
                                                value={values.maxDiscountAmount}
                                                label={"Max discount amount"}
                                                fullWidth={true}
                                                required={true}
                                                onChange={handleChange}
                                                size="small"
                                                variant="outlined"
                                                type="number"
                                                onBlur={handleBlur}
                                                onWheel={
                                                    disableScrollWheelInput
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            ₹
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={
                                                    Boolean(
                                                        errors.maxDiscountAmount,
                                                    ) &&
                                                    touched.maxDiscountAmount
                                                }
                                                helperText={
                                                    touched.maxDiscountAmount &&
                                                    errors.maxDiscountAmount
                                                }
                                            />
                                        </TextFieldContainer>
                                    </Row>
                                )}
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            type="number"
                                            name="usageLimitByTotal"
                                            fullWidth={true}
                                            value={values.usageLimitByTotal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Total usage limit"
                                            size="small"
                                            variant="outlined"
                                            onWheel={disableScrollWheelInput}
                                            error={
                                                Boolean(
                                                    errors.usageLimitByTotal,
                                                ) && touched.usageLimitByTotal
                                            }
                                            helperText={
                                                touched.usageLimitByTotal &&
                                                errors.usageLimitByTotal
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <StyledTooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                "Limit the number of times this discount can be used in total"
                                                            }
                                                        >
                                                            <Info
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </StyledTooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            type="number"
                                            fullWidth={true}
                                            name="usageLimitByCustomer"
                                            value={values.usageLimitByCustomer}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Usage limit per customer"
                                            size="small"
                                            variant="outlined"
                                            onWheel={disableScrollWheelInput}
                                            error={
                                                Boolean(
                                                    errors.usageLimitByCustomer,
                                                ) &&
                                                touched.usageLimitByCustomer
                                            }
                                            helperText={
                                                touched.usageLimitByCustomer &&
                                                errors.usageLimitByCustomer
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <StyledTooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                "Limit the maximum no. of times this discount can be used by an eligible customer"
                                                            }
                                                        >
                                                            <Info
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </StyledTooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TextFieldContainer>
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <FormControl
                                            size="small"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel>Category</InputLabel>
                                            <SelectField
                                                name="category"
                                                label="Category"
                                                fullWidth={true}
                                                size="small"
                                                value={values.category}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    Boolean(errors.category) &&
                                                    touched.category
                                                }
                                                IconComponent={(props) => (
                                                    <StyledArrowDown
                                                        {...props}
                                                    />
                                                )}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                <>
                                                                    Category of
                                                                    the discount
                                                                    code.
                                                                    <br />
                                                                    <br />
                                                                    Public
                                                                    discounts:
                                                                    Listed on
                                                                    the
                                                                    storefront
                                                                    website
                                                                    publicly.
                                                                    Can also be
                                                                    applied by
                                                                    typing the
                                                                    discount
                                                                    code.
                                                                    <br />
                                                                    <br />
                                                                    Private
                                                                    discounts:
                                                                    Reserved for
                                                                    specific
                                                                    customers/purpose
                                                                    and are not
                                                                    publicly
                                                                    listed. They
                                                                    can only be
                                                                    applied by
                                                                    typing the
                                                                    discount
                                                                    code.
                                                                    <br />
                                                                    <br />
                                                                    Payment
                                                                    Method based
                                                                    discounts:
                                                                    Associated
                                                                    with
                                                                    specific
                                                                    payment
                                                                    methods and
                                                                    are applied
                                                                    automatically.
                                                                </>
                                                            }
                                                        >
                                                            <InfoRounded
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                            >
                                                {priceRuleCategoryList.map(
                                                    (category) => (
                                                        <MenuItem
                                                            key={category}
                                                            value={category}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    PriceRuleCategoryNames[
                                                                        category as PriceRuleCategoryType
                                                                    ]
                                                                }
                                                            />
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </SelectField>
                                            {Boolean(errors.category) &&
                                                touched.category && (
                                                    <RadioErrorMessage>
                                                        {errors.category}
                                                    </RadioErrorMessage>
                                                )}
                                        </FormControl>
                                    </TextFieldContainer>
                                    {values.category ===
                                        PriceRuleCategoryType.PAYMENT_METHODS && (
                                        <TextFieldContainer>
                                            {values.category ===
                                                PriceRuleCategoryType.PAYMENT_METHODS && (
                                                <FormControl
                                                    size="small"
                                                    fullWidth={true}
                                                    required={
                                                        values.category ===
                                                        PriceRuleCategoryType.PAYMENT_METHODS
                                                    }
                                                >
                                                    <InputLabel>
                                                        Payment method
                                                    </InputLabel>
                                                    <SelectField
                                                        name="prereqPaymentMethod"
                                                        required={
                                                            values.category ===
                                                            PriceRuleCategoryType.PAYMENT_METHODS
                                                        }
                                                        label="Payment method"
                                                        size="small"
                                                        value={
                                                            values.prereqPaymentMethod
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            Boolean(
                                                                errors.prereqPaymentMethod,
                                                            ) &&
                                                            touched.prereqPaymentMethod
                                                        }
                                                    >
                                                        {paymentMethodsList.map(
                                                            (method) => (
                                                                <MenuItem
                                                                    key={method}
                                                                    value={
                                                                        method
                                                                    }
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            PaymentMethodNames[
                                                                                method as PaymentMethodType
                                                                            ]
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </SelectField>
                                                    {Boolean(
                                                        errors.prereqPaymentMethod,
                                                    ) &&
                                                        touched.prereqPaymentMethod && (
                                                            <RadioErrorMessage>
                                                                {
                                                                    errors.prereqPaymentMethod
                                                                }
                                                            </RadioErrorMessage>
                                                        )}
                                                </FormControl>
                                            )}
                                        </TextFieldContainer>
                                    )}
                                </Row>
                            </DiscountSection>
                        </Paper>
                        {/* Discount codes */}
                        <DiscountCodeDataGrid
                            discountCodes={values.discountCodes}
                            createNewPriceRule={newPriceRule}
                            onAdd={onAddDiscountCode}
                            priceRuleId={params.id}
                        />
                        {/* Cart prereqs */}
                        <Paper>
                            <DiscountSection>
                                <SectionTitle>Cart prerequisites</SectionTitle>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            type="number"
                                            fullWidth={true}
                                            name="prereqSubtotalMin"
                                            value={values.prereqSubtotalMin}
                                            onChange={handleChange}
                                            label="Min TSP"
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onWheel={disableScrollWheelInput}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        ₹
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <StyledTooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                "The minimum Total Selling Price (TSP) which is needed, if any, to apply this discount."
                                                            }
                                                        >
                                                            <Info
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </StyledTooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                Boolean(
                                                    errors.prereqSubtotalMin,
                                                ) && touched.prereqSubtotalMin
                                            }
                                            helperText={
                                                touched.prereqSubtotalMin &&
                                                errors.prereqSubtotalMin
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer />
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            name="prereqQuantityMin"
                                            fullWidth={true}
                                            value={values.prereqQuantityMin}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Min quantity of items"
                                            size="small"
                                            variant="outlined"
                                            type="number"
                                            onWheel={disableScrollWheelInput}
                                            error={
                                                Boolean(
                                                    errors.prereqQuantityMin,
                                                ) && touched.prereqQuantityMin
                                            }
                                            helperText={
                                                touched.prereqQuantityMin &&
                                                errors.prereqQuantityMin
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <StyledTooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                "The minimum number of items required in the cart, irrespective of the product type, to be able to apply the discount."
                                                            }
                                                        >
                                                            <Info
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </StyledTooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer />
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            fullWidth={true}
                                            name="prereqVariantsRequired"
                                            value={
                                                values.prereqVariantsRequired
                                            }
                                            placeholder="Quantity"
                                            type="number"
                                            label="Prerequisite variants required"
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onWheel={disableScrollWheelInput}
                                            error={
                                                Boolean(
                                                    errors.prereqVariantsRequired,
                                                ) &&
                                                touched.prereqVariantsRequired
                                            }
                                            helperText={
                                                touched.prereqVariantsRequired &&
                                                errors.prereqVariantsRequired
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <StyledTooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                "The minimum number of prerequisite product variants required in the cart to be able to apply the discount."
                                                            }
                                                        >
                                                            <Info
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </StyledTooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer />
                                </Row>
                                <ProductVariants
                                    priceRuleProductVariantIds={
                                        values.variantIds
                                    }
                                    onChange={handlePrereqVariantIdsChange}
                                />
                            </DiscountSection>
                        </Paper>
                        {/* Customer eligibility */}
                        <Paper>
                            <DiscountSection>
                                <SubtitleContainer>
                                    <SectionTitle>
                                        Customer eligibility
                                    </SectionTitle>
                                    <StyledTooltip
                                        enterTouchDelay={0}
                                        placement="top"
                                        title={
                                            "Specifies if the discount can be used by all customers or selected customer groups"
                                        }
                                    >
                                        <Info
                                            htmlColor="#626262"
                                            fontSize="small"
                                        />
                                    </StyledTooltip>
                                </SubtitleContainer>
                                <RadioGroup
                                    defaultValue={CustomerSelection.ALL}
                                    name="customerSelection"
                                    value={values.customerSelection}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        value={CustomerSelection.ALL}
                                        control={<Radio />}
                                        label="All customers"
                                    />
                                    <FormControlLabel
                                        value={CustomerSelection.PREREQ}
                                        control={<Radio />}
                                        label="Specific customer segments"
                                    />
                                    {Number(values.customerSelection) ===
                                        CustomerSelection.PREREQ && (
                                        <>
                                            <CustomerGroups
                                                priceRuleCustomerGroupIds={
                                                    values.prereqCustomerGroupIds
                                                }
                                                onChange={
                                                    handlePrereqCustomerGroupChange
                                                }
                                            />
                                            {errors.prereqCustomerGroupIds && (
                                                <ErrorMessage>
                                                    {
                                                        errors.prereqCustomerGroupIds
                                                    }
                                                </ErrorMessage>
                                            )}
                                        </>
                                    )}

                                    <Tooltip title="This feature will soon be enabled">
                                        <FormControlLabel
                                            disabled={true}
                                            value="specific-customers"
                                            control={<Radio />}
                                            label="Specific customers"
                                        />
                                    </Tooltip>
                                </RadioGroup>
                            </DiscountSection>
                        </Paper>
                        {/* Activation */}
                        <Paper>
                            <DiscountSection>
                                <SubtitleContainer>
                                    <SectionTitle>Activation</SectionTitle>
                                    <StyledTooltip
                                        enterTouchDelay={0}
                                        placement="top"
                                        title={
                                            "Specifies the duration within which the discount is going to remain active."
                                        }
                                    >
                                        <Info
                                            htmlColor="#626262"
                                            fontSize="small"
                                        />
                                    </StyledTooltip>
                                </SubtitleContainer>
                                <DateContainer>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DemoContainer
                                            components={["DatePicker"]}
                                        >
                                            <DatePicker
                                                label="Start date"
                                                value={values.startDate}
                                                onChange={handleStartDateChange}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DemoContainer
                                            components={["DatePicker"]}
                                        >
                                            <TimePicker
                                                label="Start time"
                                                value={values.startTime}
                                                onChange={handleStartTimeChange}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </DateContainer>
                                <FormControlLabel
                                    name="enableEndTime"
                                    label="Set end time"
                                    control={
                                        <Checkbox
                                            checked={values.enableEndTime}
                                            onChange={handleChange}
                                        />
                                    }
                                />
                                {values.enableEndTime && (
                                    <DateContainer>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DemoContainer
                                                components={["DatePicker"]}
                                            >
                                                <DatePicker
                                                    label="End date"
                                                    value={values.endDate}
                                                    onChange={
                                                        handleEndDateChange
                                                    }
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DemoContainer
                                                components={["DatePicker"]}
                                            >
                                                <TimePicker
                                                    label="End time"
                                                    value={values.endTime}
                                                    onChange={
                                                        handleEndTimeChange
                                                    }
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </DateContainer>
                                )}
                            </DiscountSection>
                        </Paper>
                    </LeftContainer>
                    <RightContainer>
                        <SummaryContainer>
                            <Paper>
                                <SectionTitle>Summary</SectionTitle>
                                <List>
                                    <ListItem>For Online Store</ListItem>
                                    {Number(values.discountType) ===
                                        DiscountType.FIXED &&
                                        values.discountValue !== "" && (
                                            <ListItem>
                                                ₹{values.discountValue} off
                                            </ListItem>
                                        )}

                                    {Number(values.discountType) ===
                                        DiscountType.PERCENTAGE &&
                                        values.discountValue !== "" && (
                                            <ListItem>
                                                {values.discountValue}% off
                                                {values.maxDiscountAmount
                                                    ? ` upto ₹${values.maxDiscountAmount}`
                                                    : ""}
                                            </ListItem>
                                        )}

                                    <ListItem>
                                        {typeof values.prereqVariantsRequired !==
                                        "number"
                                            ? 0
                                            : values.prereqVariantsRequired}{" "}
                                        prereq variants required
                                    </ListItem>
                                    {values.prereqSubtotalMin === "" &&
                                        values.prereqQuantityMin === "" && (
                                            <ListItem>
                                                No minimum purchase requirement
                                            </ListItem>
                                        )}
                                    {values.prereqSubtotalMin !== "" && (
                                        <ListItem>
                                            Minimum purchase of ₹
                                            {values.prereqSubtotalMin}
                                        </ListItem>
                                    )}
                                    {values.prereqQuantityMin !== "" && (
                                        <ListItem>
                                            Minimum purchase of ₹
                                            {values.prereqQuantityMin}
                                        </ListItem>
                                    )}
                                    {values.customerSelection ===
                                        CustomerSelection.ALL && (
                                        <ListItem>All customers</ListItem>
                                    )}
                                    {values.customerSelection !==
                                        CustomerSelection.PREREQ && (
                                        <ListItem>
                                            Selected customer groups
                                        </ListItem>
                                    )}
                                    {!values.usageLimitByCustomer &&
                                        !values.usageLimitByTotal && (
                                            <ListItem>No usage limits</ListItem>
                                        )}
                                    {values.usageLimitByTotal && (
                                        <ListItem>
                                            Limit of {values.usageLimitByTotal}{" "}
                                            users
                                            {values.usageLimitByCustomer &&
                                                `, ${values.usageLimitByCustomer} per customer`}
                                        </ListItem>
                                    )}
                                </List>
                            </Paper>
                            {priceRule.data && (
                                <Paper>
                                    <SectionTitle>Activity</SectionTitle>
                                    <StyledTimeline>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <StyledTimelineDot color="secondary">
                                                    <AddOutlined fontSize="small" />
                                                </StyledTimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <StyledTimelineContent>
                                                <TimelineTitle>
                                                    {`Created by ${priceRule.data.createdBy.firstName} ${priceRule.data.createdBy.lastName}`}
                                                </TimelineTitle>
                                                <TimelineText>
                                                    {`at ${format(
                                                        priceRule.data
                                                            .createdAt,
                                                        "dd-MM-yyyy, p",
                                                    )}`}
                                                </TimelineText>
                                            </StyledTimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <StyledTimelineDot
                                                    variant="outlined"
                                                    color="secondary"
                                                >
                                                    <Edit
                                                        fontSize="small"
                                                        color="secondary"
                                                    />
                                                </StyledTimelineDot>
                                            </TimelineSeparator>
                                            <StyledTimelineContent>
                                                <TimelineTitle>
                                                    Last updated
                                                </TimelineTitle>
                                                <TimelineText>
                                                    {`at ${format(
                                                        priceRule.data
                                                            .updatedAt,
                                                        "dd-MM-yyyy, p",
                                                    )}`}
                                                </TimelineText>
                                            </StyledTimelineContent>
                                        </TimelineItem>
                                    </StyledTimeline>
                                </Paper>
                            )}
                        </SummaryContainer>
                    </RightContainer>
                </Root>
            </Container>
            {params.id && (
                <GeneratorDialog
                    open={openGenerator}
                    onClose={handleCloseGenerator}
                    priceRuleId={params.id}
                    invalidQueryKeys={["priceRule", params.id]}
                />
            )}
        </>
    );
};
