import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomerReview, IExternalListPage } from "../../sdk/types";
import { api } from "../../sdk/utils";

export interface IUseGetCustomerReviews {
    queryKey: string | string[];

    limit?: number;
}

export const useGetCustomerReviews = (
    parameters: IUseGetCustomerReviews,
): UseInfiniteQueryResult<IExternalListPage<ICustomerReview>, unknown> => {
    const { queryKey, limit } = parameters;
    const auth = useAuth();

    return useInfiniteQuery({
        queryKey,
        queryFn: async (props) => {
            const { pageParam = "" } = props;
            return await api.v1_1.CustomerReviews.list({
                limit: limit ?? 25,
                cursor: pageParam,
                token: auth.token,
            });
        },
        getNextPageParam: (lastPage) => {
            if (!lastPage || !lastPage.cursors.next) {
                return undefined;
            }
            return lastPage.cursors.next;
        },
    });
};
