import { OrderStatus } from "./enums";

export const loaderMessages = [
    "Gathering the finest fragrances",
    "Blending the ingredients",
    "Filter the bottle...",
];

export const stateCodes: Record<string, string> = {
    AN: "Andaman and Nicobar Islands",
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chandigarh",
    CH: "Chhattisgarh",
    DN: "Dadra and Nagar Haveli",
    DD: "Daman and Diu",
    DL: "Delhi",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu and Kashmir",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    LA: "Ladakh",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PY: "Puducherry",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UK: "Uttarakhand",
    WB: "West Bengal",
};

export const paymentSuccessfulTerminalStatuses = [
    "CHARGED",
    "COD_INITIATED",
    "SUCCESS",
];

export const globalDateTimeFormat: string = "MMMM d, yyyy";

export const globalDateFormat: string = "yyyy-MM-dd";

export const orderStatusColors: Record<OrderStatus, string> = {
    [OrderStatus.CREATED]: "#2196F3", // Blue for CREATED
    [OrderStatus.SHIPPED]: "#4CAF50", // Green for SHIPPED
    [OrderStatus.RETURNED]: "#FFC107", // Amber for RETURNED
    [OrderStatus.CANCELLED]: "#F44336", // Red for CANCELLED
    [OrderStatus.FAILED]: "#FF5722", // Deep Orange for FAILED
    [OrderStatus.SYNCED]: "#607D8B", // Blue Grey for SYNCED
    [OrderStatus.DELIVERED]: "#009688", // Teal for DELIVERED
    [OrderStatus.REFUNDED]: "#FFEB3B", // Yellow for REFUNDED
    [OrderStatus.PREBOOKING_ORDER_CREATED]: "#673AB7", // Deep Purple for PREBOOKING_ORDER_CREATED
    [OrderStatus.PREBOOKED]: "#E91E63", // Pink for PREBOOKED
    [OrderStatus.CUSTOMER_CONFIRMATION_AWAITED]: "#FF9800", // Orange for CUSTOMER_CONFIRMATION_AWAITED
    [OrderStatus.CUSTOMER_CONFIRMATION_RECEIVED]: "#8BC34A", // Light Green for CUSTOMER_CONFIRMATION_RECEIVED
};

export const statusChipColors: Record<number, string> = {
    1: "#efa700", // Yellow for draft
    2: "#4CAF50", // Green for active/published
    3: "#74848c", // Grey for archived
};

export const statusChipBgColors: Record<number, string> = {
    1: "#fff9d3", // Yellow for draft
    2: "#e6ffe0", // Green for active/published
    3: "#f2f2f2", // Grey for archived
};
