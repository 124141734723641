import { UseQueryResult, useQuery } from "react-query";

import { ICollection, IExternalListPage } from "../types";
import { collections } from "../utils/api";

import { useAuth } from "./useAuth";

export const useGetCollections = (): UseQueryResult<
    IExternalListPage<ICollection>
> => {
    const auth = useAuth();

    return useQuery("collections", async () => {
        if (!auth.token) {
            throw new Error(
                "Cannot fetch product collections without authorization token.",
            );
        }

        return await collections.ProductCollection.getCollections(auth.token);
    });
};
