import { FunctionComponent, ReactElement, useState } from "react";

import { Typography, styled } from "@mui/material";

import { ICustomerReview, ICustomerReviewAsset } from "../../sdk/types";

import { CustomerReviewDetails } from "./CustomerReviewDetails";
import { CustomerReviewFooter } from "./CustomerReviewFooter";
import { CustomerReviewHeader } from "./CustomerReviewHeader";

const Root = styled("div")`
    border: 1px solid #d9d9d9;
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2)};
`;

const PrimaryImage = styled("img")`
    width: 150px;
    height: 150px;

    object-fit: contain;

    margin-right: ${({ theme }) => theme.spacing(3)};
`;

export interface ICustomerReviewProps {
    review: ICustomerReview;
}

export const CustomerReview: FunctionComponent<ICustomerReviewProps> = (
    props: ICustomerReviewProps,
): ReactElement => {
    const { review } = props;

    return (
        <Root>
            <CustomerReviewHeader review={review} />
            <CustomerReviewDetails review={review} />
            <CustomerReviewFooter review={review} />
        </Root>
    );
};
