import React, { FunctionComponent, ReactElement } from "react";

import { styled } from "@mui/material";

import { ICustomerReview } from "../../sdk/types";

const Root = styled("div")`
    display: flex;
    flex-direction: column;

    padding: ${({ theme }) => theme.spacing(2, 0)};

    gap: ${({ theme }) => theme.spacing(2)};
`;

const Content = styled("div")``;

const PrimaryImage = styled("img")`
    width: 150px;
    height: 150px;

    object-fit: contain;

    margin-right: ${({ theme }) => theme.spacing(3)};
`;

export interface ICustomerReviewDetailProps {
    review: ICustomerReview;
}

export const CustomerReviewDetails: FunctionComponent<
    ICustomerReviewDetailProps
> = (props: ICustomerReviewDetailProps): ReactElement => {
    const { review } = props;
    return (
        <Root>
            <Content>{review.content}</Content>
            {review.assets.length > 0 && (
                <>
                    {review.assets.map((asset) => (
                        <PrimaryImage
                            key={asset.id}
                            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${asset.objectKey}`}
                        />
                    ))}
                </>
            )}
        </Root>
    );
};
