import React, { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, Typography, styled } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

import { EmptyRecords } from "../../components/common";
import { useGetCollections } from "../../sdk/hooks";
import { globalDateTimeFormat } from "../../utils/constants";

const Root = styled("main")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
`;

const NoRecordsTitle = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;
`;

const CollectionsTitle = styled(Typography)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Description = styled(Typography)`
    font-size: 13px;
    color: grey;
`;

const StyledCell = styled("div")`
    display: flex;
    flex-direction: column;

    align-items: start;
`;

const columns: GridColDef[] = [
    {
        field: "title",
        headerName: "Title",
        flex: 5,
        minWidth: 300,
        renderCell: (params: GridRenderCellParams) => (
            <StyledCell>
                <CollectionsTitle>{params.row.title}</CollectionsTitle>
                <Description>{params.row.description}</Description>
            </StyledCell>
        ),
    },
    {
        field: "slug",
        headerName: "Slug",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.updatedAt), globalDateTimeFormat)}`,
    },
];

export const CollectionsScreen: FunctionComponent = (): ReactElement => {
    const collectionsQuery = useGetCollections();

    const navigate = useNavigate();

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/product-collections/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <Root>
            <TitleContainer>
                <Title>Product collections</Title>
                <Link to="/product-collections/new">
                    <Button variant="contained" startIcon={<AddIcon />}>
                        Create Collection
                    </Button>
                </Link>
            </TitleContainer>

            {(collectionsQuery?.data?.records ?? []).length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <NoRecordsTitle>
                            Group your products into categories
                        </NoRecordsTitle>
                        <Description>
                            Use collections to organize your products into
                            categories.
                        </Description>
                        <Link to="/product-collections/new">
                            <Button variant="contained" endIcon={<AddIcon />}>
                                create collection
                            </Button>
                        </Link>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}

            {(collectionsQuery?.data?.records ?? []).length > 0 && (
                <DataGrid
                    rows={collectionsQuery?.data?.records ?? []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    onRowClick={handleRowClick}
                    pageSizeOptions={[10, 20, 30]}
                    disableRowSelectionOnClick={true}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: {
                                disableToolbarButton: true,
                            },
                        },
                    }}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                />
            )}
        </Root>
    );
};
