import { FunctionComponent, ReactElement } from "react";

import {
    Icon,
    Drawer as MuiDrawer,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { useAuth } from "../../sdk/hooks";
import { AdminRole } from "../../utils/enums";

import {
    INavigationGroup,
    INavigationGroupItem,
    NavigationGroups,
} from "./NavigationGroups";

const Drawer = styled(MuiDrawer)`
    .MuiPaper-root {
        width: 280px;
        padding: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
        background-color: #042442;
        color: white;
        font-family: "Poppins";
    }
`;

const StyledIcon = styled(Icon)`
    color: white;
`;

const StyledToolbar = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(0.5)};
    justify-content: center;

    padding: ${({ theme }) => theme.spacing(2)};
`;

export interface IPrimaryDrawerProps {
    open: boolean;
    onToggle: () => void;
}

export const PrimaryDrawer: FunctionComponent<IPrimaryDrawerProps> = (
    props: IPrimaryDrawerProps,
): ReactElement => {
    const { open, onToggle } = props;
    const { user } = useAuth();

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const groups: INavigationGroup[] = [
        {
            id: "sales",
            icon: <StyledIcon>sell</StyledIcon>,
            title: "Sales",
            items: [
                {
                    id: "orders",
                    icon: <StyledIcon>inbox</StyledIcon>,
                    title: "Orders",
                    url: "/orders",
                },
                {
                    id: "customer-reviews",
                    icon: <StyledIcon>rate_review</StyledIcon>,
                    title: "Customer Reviews",
                    url: "/customer-reviews",
                },
                {
                    id: "discounts",
                    icon: <StyledIcon>discount</StyledIcon>,
                    title: "Discounts",
                    url: "/discounts",
                },
            ],
        },
        {
            id: "product_catalog",
            icon: <StyledIcon>apps</StyledIcon>,
            title: "Product Catalog",
            items: [
                {
                    id: "products",
                    icon: <StyledIcon>category</StyledIcon>,
                    title: "Products",
                    url: "/products",
                },
                {
                    id: "product-collections",
                    icon: <StyledIcon>collections_bookmark</StyledIcon>,
                    title: "Product Collections",
                    url: "/product-collections",
                },
            ],
        },
        {
            id: "entity_schemas",
            icon: <StyledIcon>schema</StyledIcon>,
            title: "Entity Schemas",
            items: [
                {
                    id: "entity-schemas",
                    icon: <StyledIcon>schema</StyledIcon>,
                    title: "Entity Schemas",
                    url: "/entity-schemas",
                },
            ],
        },
        {
            id: "link_builder",
            icon: <StyledIcon>tag</StyledIcon>,
            title: "Link Builder",
            items: [
                {
                    id: "links",
                    icon: <StyledIcon>link</StyledIcon>,
                    title: "Links",
                    url: "/links",
                },
            ],
        },
        {
            id: "customers",
            icon: <StyledIcon>groups</StyledIcon>,
            title: "Customers",
            items: [
                {
                    id: "customer",
                    icon: <StyledIcon>person</StyledIcon>,
                    title: "Customer",
                    url: "/customers",
                },
                {
                    id: "customer-groups",
                    icon: <StyledIcon>groups</StyledIcon>,
                    title: "Customer Groups",
                    url: "/customer-groups",
                },
            ],
        },
        {
            id: "management",
            icon: <StyledIcon>groups</StyledIcon>,
            title: "Administration",
            items: [
                user &&
                    [
                        AdminRole.ROOT,
                        AdminRole.ADMIN,
                        AdminRole.MANAGER,
                    ].includes(user.role) && {
                        id: "admins",
                        icon: <StyledIcon>people</StyledIcon>,
                        title: "Admins",
                        url: "/users",
                    },
                {
                    id: "admin-groups",
                    icon: <StyledIcon>supervisor_account</StyledIcon>,
                    title: "Admin Groups",
                    url: "/admin-groups",
                },
            ].filter(Boolean) as INavigationGroupItem[],
        },
    ];

    return (
        <Drawer
            variant={smallScreen ? "temporary" : "permanent"}
            anchor={"left"}
            open={open}
            onClose={onToggle}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <StyledToolbar>
                <Icon fontSize="large">hotel_class</Icon>
                <Typography
                    variant="h6"
                    noWrap={true}
                    component="div"
                    sx={{ letterSpacing: 3, fontWeight: 800 }}
                >
                    POWERHOUSE
                </Typography>
            </StyledToolbar>
            <NavigationGroups groups={groups} />
        </Drawer>
    );
};
