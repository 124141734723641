import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useVerifyCustomerReview } from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import { CustomerReviewStatus, IVerifyCustomerReview } from "../../sdk/types";

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Typography)`
    font-weight: 500;
    font-size: 24px;
`;

const FlagReviewControl = styled(FormControl)`
    width: 100%;
    margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const ModalContainer = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: fit-content;
    height: fit-content;

    outline: 0;
    background: white;

    border-radius: ${({ theme }) => theme.spacing(1)};

    display: flex;
    flex-direction: column;

    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        width: 100%;
        height: 100%;

        padding: ${({ theme }) => theme.spacing(2)};
    }
`;

const SaveButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
        position: fixed;

        border-radius: 0;

        bottom: 0px;
        left: 0px;
    }
`;

const DescriptionSection = styled(Box)`
    display: flex;
    flex-direction: column;

    width: 500px;
    max-width: 100%;

    margin-top: ${({ theme }) => theme.spacing(3)};
`;

const validationSchema = Yup.object({
    comment: Yup.string().max(1024).nullable(),
    flaggedReason: Yup.string().max(1024).nullable(),
    flaggedAt: Yup.date().nullable(),
});

export interface IModerateCustomerReviewCardProps {
    onClose: () => void;
    reviewId: string;
    status: number;
}

export const rejectionReasons = [
    { value: 1, label: "Inappropriate Content" },
    { value: 2, label: "Offensive Language" },
    { value: 3, label: "False Information" },
    { value: 4, label: "Other" },
];

export const ModerateCustomerReviewCard: FunctionComponent<
    IModerateCustomerReviewCardProps
> = (props: IModerateCustomerReviewCardProps): ReactElement => {
    const { onClose, reviewId, status } = props;

    const [formOpen, setFormOpen] = useState(true);

    const { showError, showSuccess } = useMessage();

    const verifyCustomerReviewMutation = useVerifyCustomerReview(
        "verifyCustomerReview",
    );

    const handleFormClose = useCallback(() => {
        setFormOpen(false);
        onClose();
    }, [setFormOpen, onClose]);

    const { values, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues: {
            status,
            reviewId,
            comment: null,
            flaggedReason: null,
            flaggedAt:
                status === CustomerReviewStatus.REJECTED ? new Date() : null,
        },
        onSubmit: (values0: IVerifyCustomerReview) => {
            verifyCustomerReviewMutation.mutate(
                {
                    values: values0,
                },
                {
                    onError: (error: any) => {
                        showError(error);
                        handleFormClose();
                    },
                    onSuccess: () => {
                        handleFormClose();
                        showSuccess("Review moderated successfully");
                    },
                },
            );
        },
        validationSchema,
    });

    const handleFormSubmit = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    if (verifyCustomerReviewMutation.isLoading) {
        return <CircularProgress />;
    }

    return (
        <Modal open={formOpen}>
            <ModalContainer>
                <TitleContainer>
                    {status === CustomerReviewStatus.REJECTED ? (
                        <Title>Reject review</Title>
                    ) : (
                        <Title>Approve review</Title>
                    )}
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </TitleContainer>
                {!verifyCustomerReviewMutation.isLoading && (
                    <>
                        <DescriptionSection>
                            <TextField
                                multiline={true}
                                rows={4}
                                name="comment"
                                label="Comment"
                                value={values.comment}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={false}
                            />
                        </DescriptionSection>
                        {status === CustomerReviewStatus.REJECTED && (
                            <FlagReviewControl>
                                <InputLabel>Flagged reason</InputLabel>
                                <Select
                                    name="flaggedReason"
                                    label="Flagged Reason"
                                    value={values.flaggedReason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {rejectionReasons.map((reason) => (
                                        <MenuItem
                                            key={reason.value}
                                            value={reason.value}
                                        >
                                            {reason.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FlagReviewControl>
                        )}
                        <SaveButton
                            variant="contained"
                            onClick={handleFormSubmit}
                        >
                            Submit
                        </SaveButton>
                    </>
                )}
            </ModalContainer>
        </Modal>
    );
};
