import axios from "axios";

import * as api from "../../../types/api";
import {
    ICustomerReview,
    ICustomerReviewAsset,
    IExternalListPage,
} from "../../../types";

export interface IGetCustomerReviewsParameters {
    token: string | null;
    variantIds?: string[];
    limit?: number;
    cursor?: string;
}

export interface IVerifyCustomerReviewParameters {
    token: string | null;
    status: number;
    reviewId: string;
    comment: string | null;
    flaggedReason: number | null;
    flaggedAt: Date | null;
}

export class CustomerReviews {
    private static transform = (data: any): ICustomerReview => {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),

            customer: {
                ...data.customer,
                birthday: data.customer.birthday
                    ? new Date(data.customer.birthday)
                    : null,
                createdAt: new Date(data.customer.createdAt),
                updatedAt: new Date(data.customer.updatedAt),
                emailMarketingConsentUpdatedAt: new Date(
                    data.customer.emailMarketingConsentUpdatedAt,
                ),
                smsMarketingConsentUpdatedAt: new Date(
                    data.customer.smsMarketingConsentUpdatedAt,
                ),
            },
            assets: data.assets.map((asset: ICustomerReviewAsset) => ({
                ...asset,
                createdAt: new Date(asset.createdAt),
                updatedAt: new Date(asset.createdAt),
            })),
        };
    };

    public static async list(
        parameters: IGetCustomerReviewsParameters,
    ): Promise<IExternalListPage<ICustomerReview>> {
        const { token, limit, cursor } = parameters;
        const params = new URLSearchParams();

        if (limit) {
            params.append("limit", limit.toString());
        }

        if (cursor) {
            params.append("cursor", cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-reviews`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
                params,
            },
        );
        return {
            cursors: data.cursors,
            records: data.records.map((record: ICustomerReview) =>
                this.transform(record),
            ),
        };
    }
    public static async verify(
        parameters: IVerifyCustomerReviewParameters,
    ): Promise<api.IMessage> {
        const { token, ...body } = parameters;

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-reviews/moderate`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return response.data;
    }
}
