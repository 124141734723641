import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import { Box, IconProps, Typography, styled } from "@mui/material";

import {
    ArrowBackIos as Left,
    ArrowForwardIos as Right,
} from "@mui/icons-material";

import { CircularLoader, EmptyRecords } from "../../components/common";
import { useGetCustomerReviews } from "../../hooks";
import { useGetAllProducts } from "../../sdk/hooks";

import { CustomerReview } from "./CustomerReview";

const Root = styled("div")`
    padding: ${({ theme }) => theme.spacing(3)};
`;

const IconContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: ${({ theme }) => theme.spacing(2, 0, 20, 0)};
`;

const Title = styled(Typography)`
    font-weight: 500;
    font-size: 24px;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const PageNavigator = styled(Typography)`
    color: grey;

    margin: ${({ theme }) => theme.spacing(0, 1)};
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

interface ICaretLeftProps extends IconProps {
    startIndex: number;
}

interface ICaretRightProps extends IconProps {
    lastPage: boolean;
}

const CaretLeft = styled(Left, {
    shouldForwardProp: (propName) => propName !== "startIndex",
})<ICaretLeftProps>`
    cursor: ${({ startIndex }) =>
        startIndex === 0 ? "not-allowed" : "pointer"};
    color: ${({ startIndex }) => (startIndex === 0 ? "grey" : "black")};
`;

const CaretRight = styled(Right, {
    shouldForwardProp: (propName) => propName !== "lastPage",
})<ICaretRightProps>`
    cursor: ${({ lastPage }) => (lastPage ? "not-allowed" : "pointer")};
    color: ${({ lastPage }) => (lastPage ? "grey" : "black")};
`;

const limit = 20;
const reviewsPerPage = 3;

export const CustomerReviewsScreen: FunctionComponent = (): ReactElement => {
    const [startIndex, setStartIndex] = useState(0);
    const products = useGetAllProducts("products");
    const variantIds = products.data?.flatMap((product) =>
        product.variants.map((variant) => variant.shopifyVariantId),
    );

    const getCustomerReviewsQuery = useGetCustomerReviews({
        queryKey: variantIds ?? [],
        limit,
    });

    const customerReviews = useMemo(() => {
        const pages = getCustomerReviewsQuery.data?.pages;
        if (!pages) {
            return [];
        }

        return pages.flatMap((page) => page.records);
    }, [getCustomerReviewsQuery.data?.pages]);

    const handleNextPage = useCallback(() => {
        if (startIndex + reviewsPerPage >= customerReviews.length) {
            return;
        }
        setStartIndex((prevStartIndex) => prevStartIndex + reviewsPerPage);
    }, [startIndex, customerReviews.length]);

    const handlePreviousPage = useCallback(() => {
        if (startIndex === 0) {
            return;
        }
        setStartIndex((prevStartIndex) =>
            Math.max(0, prevStartIndex - reviewsPerPage),
        );
    }, [startIndex]);

    useEffect(() => {
        if (
            startIndex > customerReviews.length - reviewsPerPage &&
            getCustomerReviewsQuery.hasNextPage
        ) {
            getCustomerReviewsQuery.fetchNextPage();
        }
    }, [startIndex, getCustomerReviewsQuery, customerReviews.length]);

    const renderReviews = useMemo(
        () => customerReviews.slice(startIndex, startIndex + reviewsPerPage),
        [customerReviews, startIndex],
    );

    const currentPage = Math.ceil((startIndex + 1) / reviewsPerPage);
    const totalPages = Math.ceil(customerReviews.length / reviewsPerPage);

    if (getCustomerReviewsQuery.isLoading) {
        return <CircularLoader />;
    }

    if (customerReviews.length === 0) {
        return (
            <EmptyRecords>
                <EmptyRecordsContainer>
                    <Title>No reviews found</Title>
                </EmptyRecordsContainer>
            </EmptyRecords>
        );
    }

    if (customerReviews.length === 0 || getCustomerReviewsQuery.isError) {
        return (
            <Title>
                Apologies, an error occurred. Please refresh the page or try
                again later.
            </Title>
        );
    }

    return (
        <Root>
            <Title>Customer Reviews</Title>
            <Container>
                {getCustomerReviewsQuery.isSuccess &&
                    renderReviews.length > 0 &&
                    renderReviews.map((review) => (
                        <CustomerReview review={review} />
                    ))}
            </Container>

            <IconContainer>
                <CaretLeft
                    onClick={handlePreviousPage}
                    startIndex={startIndex}
                />
                <PageNavigator>{`${currentPage}/${totalPages}`}</PageNavigator>
                <CaretRight
                    onClick={handleNextPage}
                    lastPage={
                        startIndex + reviewsPerPage >= customerReviews.length
                    }
                />
            </IconContainer>
        </Root>
    );
};
