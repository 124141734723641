import React, { FunctionComponent, ReactElement } from "react";

import { Avatar, Box, Chip, Rating, Typography, styled } from "@mui/material";

import { CustomerReviewStatus, ICustomerReview } from "../../sdk/types";
import { formatDate } from "../../sdk/utils/numbers";

const NameInitials = styled(Avatar)`
    background-color: #ff2d96;
    font-size: 11px;

    height: 28px;
    width: 28px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        height: 24px;
        width: 24px;

        font-size: 10px;
    }
`;

const Container = styled("div")``;

const ReviewdAt = styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.grey[700]};
`;

const ModeratedAt = styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.grey[700]};
`;

const CustomerName = styled(Typography)`
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        font-size: 14px;
    }
`;

const Root = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CustomerDetails = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: row;

    gap: ${({ theme }) => theme.spacing(2)};
`;

const StatusContainer = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(1)};
`;

const StatusLabel: Record<number, string> = {
    [CustomerReviewStatus.APPROVED]: "Approved",
    [CustomerReviewStatus.REJECTED]: "Rejected",
    [CustomerReviewStatus.PENDING]: "Pending",
};

const StatusColor: Record<number, any> = {
    [CustomerReviewStatus.APPROVED]: "success",
    [CustomerReviewStatus.REJECTED]: "error",
    [CustomerReviewStatus.PENDING]: "primary",
};

export interface ICustomerReviewHeaderProps {
    review: ICustomerReview;
}

export const CustomerReviewHeader: FunctionComponent<
    ICustomerReviewHeaderProps
> = (props: ICustomerReviewHeaderProps): ReactElement => {
    const { review } = props;

    return (
        <Root>
            <CustomerDetails>
                <NameInitials>{`${review.customer.firstName.charAt(
                    0,
                )}${review.customer.lastName.charAt(0)}`}</NameInitials>
                <Container>
                    <CustomerName>
                        {review.customer.firstName} {review.customer.lastName}
                    </CustomerName>
                    <ReviewdAt>
                        Reviewed on {formatDate(review.updatedAt)}
                    </ReviewdAt>
                    <Rating
                        value={review.rating}
                        precision={0.5}
                        readOnly
                        size="small"
                    />
                </Container>
            </CustomerDetails>
            <StatusContainer>
                <Chip
                    label={StatusLabel[review.status]}
                    variant="outlined"
                    size="medium"
                    color={StatusColor[review.status]}
                />
                {review.status === CustomerReviewStatus.APPROVED &&
                    review?.admin?.firstName && (
                        <ModeratedAt>
                            Approved by {review?.admin?.firstName}{" "}
                            {review?.admin?.lastName}
                        </ModeratedAt>
                    )}
                {review.status === CustomerReviewStatus.REJECTED &&
                    review?.admin?.firstName && (
                        <ModeratedAt>
                            Rejected by {review?.admin?.firstName}{" "}
                            {review?.admin?.lastName}
                        </ModeratedAt>
                    )}
            </StatusContainer>
        </Root>
    );
};
