import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import { Box, Button, Divider as MuiDivider, styled } from "@mui/material";

import { CustomerReviewStatus, ICustomerReview } from "../../sdk/types";
import { formatDate } from "../../sdk/utils/numbers";

import { ModerateCustomerReviewCard } from "./ModerateCustomerReviewCard";

const Root = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    justify-content: space-between;
`;

const ProductContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Container = styled("div")``;

const VariantContainer = styled("div")`
    color: ${({ theme }) => theme.palette.grey[700]};
`;

const Divider = styled(MuiDivider)`
    height: unset;
`;

const PrimaryImage = styled("img")`
    width: 150px;
    height: 150px;

    object-fit: cover;
`;

const ButtonContainer = styled(Box)`
    display: flex;
    align-items: flex-end;
    flex-direction: row;

    gap: ${({ theme }) => theme.spacing(2)};
`;

export interface ICustomerReviewFooterProps {
    review: ICustomerReview;
}

export const CustomerReviewFooter: FunctionComponent<
    ICustomerReviewFooterProps
> = (props: ICustomerReviewFooterProps): ReactElement => {
    const { review } = props;

    const [showReviewVerificationModal, setShowReviewVerificationModal] =
        useState(false);
    const [moderatedStatus, setModeratedStatus] = useState(review.status);

    const handleModerateReview = useCallback((status: number) => {
        setModeratedStatus(status);
        setShowReviewVerificationModal(true);
    }, []);

    const handleReviewVerificationModal = useCallback(() => {
        setShowReviewVerificationModal(false);
    }, []);

    return (
        <>
            <Root>
                <ProductContainer>
                    <PrimaryImage
                        src={review.variant.images?.[0]?.objectUrl ?? ""}
                    />
                    <Container>
                        <Container>{review.variant.product.title}</Container>
                        <VariantContainer>
                            {review.variant.title}
                        </VariantContainer>
                    </Container>
                </ProductContainer>
                <Divider orientation="vertical" />
                <Container>
                    <div>{review.order?.name ?? ""}</div>
                    <div>{review.order?.id}</div>
                    {review.order?.updatedAt && (
                        <div>
                            Ordered on{" "}
                            {formatDate(new Date(review.order.updatedAt))}
                        </div>
                    )}
                </Container>
                <Divider orientation="vertical" />
                <Container>
                    <div>
                        {review.customer.firstName} {review.customer.lastName}
                    </div>
                    <div>{review.customer.phoneNumber}</div>
                    <div>{review.customer.emailAddress}</div>
                </Container>
            </Root>
            <ButtonContainer>
                <Button
                    onClick={() =>
                        handleModerateReview(CustomerReviewStatus.APPROVED)
                    }
                    disabled={review.status === CustomerReviewStatus.APPROVED}
                    color="success"
                    variant="contained"
                >
                    Approve
                </Button>
                <Button
                    onClick={() =>
                        handleModerateReview(CustomerReviewStatus.REJECTED)
                    }
                    disabled={review.status === CustomerReviewStatus.REJECTED}
                    color="error"
                    variant="outlined"
                >
                    Reject
                </Button>
            </ButtonContainer>
            {showReviewVerificationModal && (
                <ModerateCustomerReviewCard
                    onClose={handleReviewVerificationModal}
                    reviewId={review.id}
                    status={moderatedStatus}
                />
            )}
        </>
    );
};
