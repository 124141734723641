import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Button,
    Icon,
    Chip as MuiChip,
    Typography,
    styled,
} from "@mui/material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import {
    CircularLoader,
    EmptyRecords,
    WorkspaceToolbar,
} from "../../components/common";
import { useGetAllProducts } from "../../sdk/hooks";
import { productStatusByEnum } from "../../utils/enums";

import { StatusFilter } from "./StatusFilter";

const Root = styled("main")``;

const Content = styled("div")`
    padding: ${({ theme }) => theme.spacing(2)};

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const Chip = styled(MuiChip)`
    text-transform: capitalize;
`;

const columns: GridColDef[] = [
    {
        field: "title",
        headerName: "Title",
        flex: 0.4,
        minWidth: 300,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.3,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip
                label={productStatusByEnum[params.value]}
                size="small"
                variant="outlined"
            />
        ),
    },
    {
        field: "bestSellerScore",
        headerName: "Bestseller Score",
        flex: 0.2,
        minWidth: 170,
    },
    {
        field: "featureScore",
        headerName: "Feature Score",
        flex: 0.2,
        minWidth: 150,
    },
    {
        field: "tagLine",
        headerName: "Tag Line",
        flex: 1,
    },
];
const defaultProductStatuses = Object.keys(productStatusByEnum);

const filterSchema = Yup.object({
    status: Yup.array().of(Yup.string()),
});

export interface IFilterSchemaParameters {
    status: string[];
}

export const ViewProductsScreen: FunctionComponent = (): ReactElement => {
    /*
     *  `onSubmit` simply returns void because onSubmit is required in useFormik schema
     *  if we do not add an `onSubmit` then TS throws an error
     */
    const navigate = useNavigate();
    const { values, setFieldValue } = useFormik<IFilterSchemaParameters>({
        initialValues: {
            status: defaultProductStatuses,
        },
        validationSchema: filterSchema,
        onSubmit: (values) => {
            return;
        },
    });

    const fetchProductsQuery = useGetAllProducts(
        ["products", values.status.toString()],
        {
            status: values.status,
        },
    );

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/products/${params.row.id}`);
        },
        [navigate],
    );

    if (fetchProductsQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <WorkspaceToolbar
                title="Products"
                enableSearch={false}
                actions={
                    <Link to="/products/new">
                        <Button
                            size="small"
                            variant="contained"
                            disableElevation={true}
                            endIcon={<Icon>add_circle</Icon>}
                        >
                            Create
                        </Button>
                    </Link>
                }
            />
            <Content>
                <StatusFilter
                    selectedStatus={values.status}
                    onChangeFieldValue={setFieldValue}
                />
                {fetchProductsQuery?.data?.length === 0 && (
                    <EmptyRecords>
                        <EmptyRecordsContainer>
                            <Title>No products found</Title>
                        </EmptyRecordsContainer>
                    </EmptyRecords>
                )}
                {(fetchProductsQuery?.data ?? []).length > 0 && (
                    <StyledDataGrid
                        rows={fetchProductsQuery?.data ?? []}
                        columns={columns}
                        disableRowSelectionOnClick={true}
                        onRowClick={handleRowClick}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                    />
                )}
            </Content>
        </Root>
    );
};
