import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Chip as MuiChip,
    ListItemText as MuiListItemText,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    styled,
} from "@mui/material";

import { productStatusByEnum } from "../../utils/enums";

const Root = styled("div")`
    width: 300px;
`;

const Chip = styled(MuiChip)`
    margin-left: ${({ theme }) => theme.spacing(1)};
    text-transform: capitalize;
`;

const ListItemText = styled(MuiListItemText)`
    text-transform: capitalize;
`;

const orderStatusList = Object.keys(productStatusByEnum);

export interface IStatusFilterProps {
    selectedStatus: string[];
    onChangeFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void;
}

export const StatusFilter: FunctionComponent<IStatusFilterProps> = (
    props: IStatusFilterProps,
): ReactElement => {
    const { selectedStatus, onChangeFieldValue } = props;

    /*
     * We are using a wrapper around the `setFieldValue` instead of directly using onChange
     * as there are some typescript issue between the `onChange` of `Select` and `onChange` of `useFormik`
     * Example: Select event is `SelectChangeEvent` and the event in `onChange` is ChangeEvent
     */
    const handleStatusChange = useCallback(
        (event: SelectChangeEvent<string[]>) => {
            onChangeFieldValue("status", event.target.value);
        },
        [onChangeFieldValue],
    );

    return (
        <Root>
            <FormControl fullWidth={true}>
                <InputLabel>Status</InputLabel>
                <Select
                    size="small"
                    name="status"
                    multiple={true}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    input={<OutlinedInput label="Status" />}
                    renderValue={(selected) =>
                        selected.map((status, index) => (
                            <Chip
                                key={index}
                                size="small"
                                label={productStatusByEnum[Number(status)]}
                                variant="outlined"
                            />
                        ))
                    }
                >
                    {orderStatusList.map((status) => (
                        <MenuItem key={status} value={status}>
                            <Checkbox
                                checked={selectedStatus.indexOf(status) > -1}
                            />
                            <ListItemText
                                primary={productStatusByEnum[Number(status)]}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Root>
    );
};
