import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import { Typography, styled } from "@mui/material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { DataGridFooter } from "../../components/common";
import { useGetAllCustomers } from "../../hooks";
import { IPage } from "../../types";
import { globalDateTimeFormat } from "../../utils/constants";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Heading = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.firstName && params.row.lastName
                ? `${params.row.firstName} ${params.row.lastName}`
                : "",
    },

    {
        field: "emailAddress",
        headerName: "Email",
        flex: 0.8,
        valueGetter: (params: GridValueGetterParams) => params.row.emailAddress,
    },
    {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) => params.row.phoneNumber,
    },

    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.updatedAt), globalDateTimeFormat)}`,
    },
];

const filterSchema = Yup.object({
    limit: Yup.number(),
    cursor: Yup.string().nullable(),
    pageNumber: Yup.number(),
    direction: Yup.mixed().oneOf(["after", "before"]),
});

export const ViewCustomersScreen: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    /**
     *  `onSubmit` simply returns void because onSubmit is required in useFormik schema
     *  if we do not add an `onSubmit` then TS throws an error
     */
    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });
    const { values, setFieldValue } = useFormik({
        initialValues: {
            limit: page.limit,
            cursor: page.cursor,
            pageNumber: 0,
            direction: page.direction,
        },
        validationSchema: filterSchema,
        onSubmit: (values) => {
            return;
        },
    });

    const getCustomersQuery = useGetAllCustomers(
        [
            "users",
            values.limit.toString(),
            values.cursor ?? "",
            values.direction,
        ],
        {
            limit: values.limit,
            cursor: values.cursor,
            direction: values.direction,
        },
    );

    const handleNavigateBack = useCallback(() => {
        setPage({
            ...page,
            cursor: getCustomersQuery.data?.cursors?.previous ?? "",
            direction: "before",
        });
        setFieldValue(
            "cursor",
            getCustomersQuery?.data?.cursors?.previous ?? "",
        );
        setFieldValue("direction", "before");
    }, [page, getCustomersQuery.data?.cursors?.previous, setFieldValue]);

    const handleNavigateForward = useCallback(() => {
        setPage({
            ...page,
            cursor: getCustomersQuery.data?.cursors?.next ?? "",
            direction: "after",
        });
        setFieldValue("cursor", getCustomersQuery?.data?.cursors?.next ?? "");
        setFieldValue("direction", "after");
    }, [page, getCustomersQuery.data?.cursors?.next, setFieldValue]);

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/customers/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <Root>
            <TitleContainer>
                <Heading>Customers</Heading>
            </TitleContainer>

            {getCustomersQuery.isSuccess &&
                getCustomersQuery?.data?.records?.length > 0 && (
                    <StyledDataGrid
                        autoHeight={true}
                        rows={getCustomersQuery?.data?.records ?? []}
                        columns={columns}
                        onRowClick={handleRowClick}
                        loading={getCustomersQuery.isLoading}
                        slots={{
                            toolbar: GridToolbar,
                            footer: () => (
                                <DataGridFooter
                                    hasNextRecords={Boolean(
                                        getCustomersQuery.data.cursors.next,
                                    )}
                                    hasPreviousRecords={Boolean(
                                        getCustomersQuery.data.cursors.previous,
                                    )}
                                    onNavigateBack={handleNavigateBack}
                                    onNavigateForward={handleNavigateForward}
                                />
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: {
                                    disableToolbarButton: false,
                                },
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                            },
                        }}
                    />
                )}
        </Root>
    );
};
