import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Button,
    ButtonBase,
    Dialog,
    DialogTitle,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import { Add, Close, Info } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowSelectionModel,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";

import { EmptyRecords, StatusChip } from "../../components/common";
import { useGetAllProducts } from "../../sdk/hooks";
import { formatCurrency } from "../../sdk/utils/numbers";
import { globalDateTimeFormat } from "../../utils/constants";
import { ProductStatus, productStatusByEnum } from "../../utils/enums";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(1, 2)};
    max-width: 100%;
    min-height: 500px;

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }

    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;
const Header = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const FormHeading = styled(Typography)`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};

    font-size: 20px;
    font-weight: 500;
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const ButtonContainer = styled("div")`
    width: 100%;
    display: flex;
    justify-content: end;
`;

const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

const InfoText = styled(Typography)`
    display: flex;
    width: 100%;
`;

const columns: GridColDef[] = [
    {
        field: "productName",
        headerName: "Product",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => params.row.productTitle,
    },
    {
        field: "name",
        headerName: "Variant",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => params.row.title,
    },
    {
        field: "mrp",
        headerName: "MRP",
        flex: 0.3,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.mrp),
    },
    {
        field: "price",
        headerName: "Selling price",
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.price),
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.3,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <StatusChip
                label={productStatusByEnum[params.value]}
                value={params.value}
            />
        ),
    },
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

const selectedVariantsColumns: GridColDef[] = [
    {
        field: "productName",
        headerName: "Product",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => params.row.productTitle,
    },
    {
        field: "name",
        headerName: "Variant",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => params.row.title,
    },
    {
        field: "mrp",
        headerName: "MRP",
        flex: 0.3,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.mrp),
    },
    {
        field: "price",
        headerName: "Selling price",
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.price),
    },
];

export interface IProductVariantsProps {
    priceRuleProductVariantIds: string[];
    onChange: any;
}

export const ProductVariants: FunctionComponent<IProductVariantsProps> = (
    props: IProductVariantsProps,
): ReactElement => {
    const { priceRuleProductVariantIds, onChange } = props;
    const [open, setOpen] = useState(false);

    const handleToggleDialog = useCallback(() => {
        setOpen((oldOpen) => !oldOpen);
    }, []);

    const fetchProductsQuery = useGetAllProducts(
        ["products", ProductStatus.PUBLISHED.toString()],
        {
            status: [ProductStatus.PUBLISHED.toString()],
        },
    );

    const products = useMemo(() => {
        const productList = fetchProductsQuery.data ?? [];
        return productList;
    }, [fetchProductsQuery.data]);

    const productVariants = products.flatMap((product) =>
        product.variants.map((variant) => ({
            ...variant,
            productTitle: product.title,
        })),
    );

    const [selectedProductVariantIds, setSelectedProductVariantIds] =
        useState<GridRowId[]>();

    useEffect(() => {
        setSelectedProductVariantIds(priceRuleProductVariantIds);
    }, [priceRuleProductVariantIds]);

    const handleCancel = useCallback(() => {
        /* Undo the changes made */
        setSelectedProductVariantIds(priceRuleProductVariantIds);

        /* Close the dialog */
        handleToggleDialog();
    }, [handleToggleDialog, priceRuleProductVariantIds]);

    const handleSubmit = useCallback(() => {
        onChange(selectedProductVariantIds);
        handleToggleDialog();
    }, [handleToggleDialog, onChange, selectedProductVariantIds]);

    const handleRowSelection = useCallback(
        (newSelection: GridRowSelectionModel) => {
            setSelectedProductVariantIds(newSelection as string[]);
        },
        [],
    );

    return (
        <>
            <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="lg">
                <Header>
                    <FormHeading>
                        Select product variants
                        <Tooltip
                            enterTouchDelay={0}
                            title="Specifies prerequisite product variants that must be present in the cart to apply this discount"
                        >
                            <Info />
                        </Tooltip>
                    </FormHeading>
                    <FormCloseHandler>
                        <FormDrawerCloseButton onClick={handleCancel}>
                            <Close />
                        </FormDrawerCloseButton>
                    </FormCloseHandler>
                </Header>
                <Root>
                    {fetchProductsQuery.data?.length === 0 && (
                        <EmptyRecords>
                            <EmptyRecordsContainer>
                                <Title>No product variants available</Title>
                                <div>
                                    Create at least one variant associated with
                                    a product use this feature
                                </div>
                            </EmptyRecordsContainer>
                        </EmptyRecords>
                    )}
                    {fetchProductsQuery.isSuccess &&
                        fetchProductsQuery.data?.length > 0 && (
                            <StyledDataGrid
                                rows={productVariants ?? []}
                                columns={columns}
                                rowSelection={true}
                                rowSelectionModel={selectedProductVariantIds}
                                checkboxSelection={true}
                                onRowSelectionModelChange={handleRowSelection}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                loading={fetchProductsQuery.isLoading}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: {
                                            disableToolbarButton: true,
                                        },
                                        printOptions: {
                                            disableToolbarButton: true,
                                        },
                                    },
                                }}
                            />
                        )}
                    <ButtonContainer>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </ButtonContainer>
                </Root>
            </Dialog>
            {priceRuleProductVariantIds.length === 0 && (
                <Row>
                    <InfoText>No product variants selected</InfoText>
                    <ButtonContainer>
                        <Button
                            variant="outlined"
                            onClick={handleToggleDialog}
                            startIcon={<Add fontSize="small" />}
                        >
                            Add product variants
                        </Button>
                    </ButtonContainer>
                </Row>
            )}
            {priceRuleProductVariantIds.length > 0 && (
                <>
                    <StyledDataGrid
                        rows={
                            productVariants.filter((variant) =>
                                priceRuleProductVariantIds.includes(variant.id),
                            ) ?? []
                        }
                        columns={selectedVariantsColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: {
                                    disableToolbarButton: true,
                                },
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                            },
                        }}
                    />
                    <Row>
                        <InfoText>
                            {`${priceRuleProductVariantIds.length} product variants selected`}
                        </InfoText>
                        <ButtonContainer>
                            <Button
                                variant="outlined"
                                onClick={handleToggleDialog}
                            >
                                Manage selection
                            </Button>
                        </ButtonContainer>
                    </Row>
                </>
            )}
        </>
    );
};
